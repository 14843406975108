import Button from '@iabbb/bds-react/Button';
import { useNavigator } from '@iabbb/context/Navigator';
import Center from '@iabbb/shared/components/General/Center';
import useCookie from '@iabbb/shared/states/User/useCookie';
import CookieNames from '@iabbb/utils/Storage/CookieNames';
import loadScript from '@iabbb/utils/browser/loadScript';
import useCookiesPolicy from '@iabbb/utils/cookies/useCookiesPolicy';
import { useEffect, useId, useRef, useState } from 'react';

import CookiesMessage from './styles';
import DialogCookiesPolicy from './DialogCookiesPolicy';

export default function CookiesMessageComponent() {
  const navigator = useNavigator();
  const isManageCookiesPage = navigator.url.pathname === '/manage-cookies';

  const [_cookiesPolicy, _setCookiesPolicy, setAcceptAllCookiesPolicy] = useCookiesPolicy();
  const [cookiesPreferencesSet] = useCookie(CookieNames.COOKIES_PREFERENCES_SET);
  const [isCookiesPolicyDialogOpen, setIsCookiesPolicyDialogOpen] = useState(false);
  const [showCookiesMessage, setShowCookiesMessage] = useState(!cookiesPreferencesSet && !isManageCookiesPage);
  const [savedCookies, setSavedCookies] = useState(false);
  const [showManageCookiesAsButton, setShowManageCookiesAsButton] = useState(true);
  const savedCookiesMessageRef = useRef<HTMLParagraphElement>(null);

  const handleManageCookiesClick = () => {
    setIsCookiesPolicyDialogOpen(true);
  };

  const headingId = useId();

  const handleAcceptAllCookiesSubmit = (event) => {
    event.preventDefault();
    setAcceptAllCookiesPolicy();
    setSavedCookies(true);
  };

  const handleHideThisMessageClick = () => {
    setShowCookiesMessage(false);
    loadScript('/TerminusContent/dist/page-load-dialogs.min.js');
  };

  useEffect(() => {
    if (!savedCookies) {
      return;
    }
    if (!savedCookiesMessageRef.current) {
      return;
    }

    savedCookiesMessageRef.current.focus();
  }, [savedCookies]);

  useEffect(() => {
    // 👇 progressive enhancement: until JS loads, just link to /manage-cookies
    setShowManageCookiesAsButton(true);
  }, []);

  if (!showCookiesMessage) {
    return null;
  }

  return (
    <>
      <CookiesMessage aria-labelledby={headingId}>
        <Center>
          <h2 className="visually-hidden" id={headingId}>
            Cookies on BBB.org
          </h2>
          {!savedCookies ? (
            <div className="layout">
              <p>
                We use cookies to give users the best content and online experience. By clicking “Accept All
                Cookies”, you agree to allow us to use all cookies. Visit our{' '}
                <a href={`${process.env.NEXT_PUBLIC_PRIVACY_POLICY_URL}#cookies`} rel="noreferrer" target="_blank">
                  Privacy Policy
                </a>{' '}
                to learn more.
              </p>
              <div className="cluster">
                <form action="/manage-cookies" onSubmit={handleAcceptAllCookiesSubmit} method="post">
                  <Button name="allow-all" value="true" variant="standard">
                    Accept All Cookies
                  </Button>
                </form>
                {showManageCookiesAsButton ? (
                  <Button onClick={handleManageCookiesClick} variant="unstyled">
                    Manage Cookies
                  </Button>
                ) : (
                  <a href="/manage-cookies">Manage Cookies</a>
                )}
              </div>
            </div>
          ) : (
            <div className="layout">
              <p ref={savedCookiesMessageRef} tabIndex={-1}>
                Your cookie settings have been saved. You can{' '}
                <a href="/manage-cookies">change your cookie settings</a> at any time.
              </p>
              <Button onClick={handleHideThisMessageClick}>Hide This Message</Button>
            </div>
          )}
        </Center>
      </CookiesMessage>
      {showManageCookiesAsButton && (
        <DialogCookiesPolicy
          isOpen={isCookiesPolicyDialogOpen}
          close={() => {
            setIsCookiesPolicyDialogOpen(false);
          }}
          setSavedCookies={setSavedCookies}
        />
      )}
    </>
  );
}
