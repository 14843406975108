import styled from '@emotion/styled';
import { UlListReset } from '@iabbb/shared/styles/listReset';

// Nav.List
export default styled(UlListReset)`
  max-height: calc(95vh - 72px);
  overflow-x: hidden;
  overflow-y: auto;
  max-width: 24rem;
  width: calc(100vw - 0.8rem);

  a {
    color: var(--bds-color-white);

    &:hover,
    &:focus {
      color: inherit;
    }
  }

  button {
    background: transparent;
  }

  @media (max-width: 28em) {
    max-width: none;
  }
`;
