import styled from '@emotion/styled';
import { UlListReset } from '@iabbb/shared/styles/listReset';

export default styled(UlListReset)`
  --_col-gap: 1rem;

  column-gap: var(--_col-gap);
  display: flex;
  flex-wrap: wrap;
  row-gap: 0.375rem;

  li {
    // 👇 we'd rather display hanging pipes on the line above (::after) than the line below (::before)
    &::after {
      content: '|';
      // 👇 half the distance of the gap, minus the approximate width of the pipe character
      margin-inline-start: calc(calc(var(--_col-gap) / 2) - 0.1em);
      position: absolute; // 👈 remove from width of parent li element
    }

    // 👇 I like :not() better, but this has slightly better browser support
    &:last-of-type::after {
      content: '';
    }
  }
`;
