import { CLOUDINARY_MEDIA_SERVER } from '@iabbb/utils/cloudinary/constants';
import React from 'react';
import urljoin from 'url-join';
import buildCloudinaryImgAttributes from '../cloudinary/buildCloudinaryImgAttributes';
import CaSilhouette from './assets/ca-country-silhouette.svg';
import UsSilhouette from './assets/us-country-silhouette.svg';

const getImageAttributes = (imageFileName: string) =>
  buildCloudinaryImgAttributes(
    urljoin(`https://${CLOUDINARY_MEDIA_SERVER}`, '/terminuscontent/dist/img/header/', imageFileName),
    21,
  );

type Country = 'can' | 'usa';

export type CultureId = 'en-ca' | 'en-us';

export type Culture = {
  bbbCountry: number;
  countryCode: number;
  countryName: string;
  cultureInfo: string;
  flagImgAttrs: {
    src: string;
    srcSet?: string;
  };
  id: CultureId;
  icon: React.FunctionComponent<React.SVGAttributes<SVGElement>>;
  label: string;
  language: string;
  twoLetterIsoLanguageName: string;
  twoLetterIsoCountryCode: string;
  threeLetterIsoCountryCode: string;
};

export type CultureById = {
  [key in CultureId]: Culture;
};

export type CultureDataType = {
  allIds: CultureId[];
  byId: CultureById;
  idsByCountry: {
    [key in Country]: CultureId;
  };
};

const CultureData: CultureDataType = {
  allIds: ['en-us', 'en-ca'],
  byId: {
    'en-us': {
      id: 'en-us',
      icon: UsSilhouette,
      label: 'US',
      cultureInfo: 'en-US',
      flagImgAttrs: getImageAttributes('flag-us-100__100w.png'),
      language: 'English',
      twoLetterIsoLanguageName: 'en',
      twoLetterIsoCountryCode: 'US',
      threeLetterIsoCountryCode: 'USA',
      countryName: 'United States',
      countryCode: 2,
      bbbCountry: 0,
    },
    'en-ca': {
      id: 'en-ca',
      icon: CaSilhouette,
      label: 'Canada',
      cultureInfo: 'en-CA',
      flagImgAttrs: getImageAttributes('flag-ca-100__100w.png'),
      language: 'English',
      twoLetterIsoLanguageName: 'en',
      twoLetterIsoCountryCode: 'CA',
      threeLetterIsoCountryCode: 'CAN',
      countryName: 'Canada',
      countryCode: 4,
      bbbCountry: 1,
    },
  },
  idsByCountry: {
    usa: 'en-us',
    can: 'en-ca',
  },
};

export default CultureData;
