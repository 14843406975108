import styled from '@emotion/styled';
import { UlListReset } from '@iabbb/shared/styles/listReset';
import media from '@iabbb/shared/styles/media';

export default styled(UlListReset)`
  ${media.xsDown} {
    a {
      display: inline-block;
      margin-block: 0.5rem;
    }
  }
`;
