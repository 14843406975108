import PropTypes from 'prop-types';

import BottomLinkList from './styles';

function BottomLinkListComponent({ links }) {
  return (
    <BottomLinkList>
      {links.map((link) => (
        <li key={`link_${link.id}`}>
          <a href={link.href} target={link.target || undefined} className="dtm-footer-link">
            {link.linkLabel}
          </a>
        </li>
      ))}
    </BottomLinkList>
  );
}

BottomLinkListComponent.propTypes = {
  links: PropTypes.array.isRequired,
};

export default BottomLinkListComponent;
