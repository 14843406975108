import Typography from '@iabbb/bds-react/Typography';
import { FieldsetReset } from '@iabbb/shared/styles/fieldsetReset';
import { useId } from 'react';

import { StyledDetails } from './styles';

export type CookiesGroupProps = {
  asFieldset?: boolean;
  details: string;
  name: string;
};

export default function CookiesGroup({
  asFieldset = true,
  details,
  name,
  children,
  ...props
}: CookiesGroupProps & React.ComponentPropsWithoutRef<'div'>) {
  const headingId = useId();

  return (
    <div className="stack-space-24" {...props}>
      <Typography component="h3" id={headingId} variant="h4">
        {name}
      </Typography>
      <StyledDetails>
        <summary>What are {name.toLowerCase()}?</summary>
        <div>{details}</div>
      </StyledDetails>
      {asFieldset ? (
        <FieldsetReset aria-labelledby={headingId} style={{ marginBlockStart: '0.75rem' }}>
          {children}
        </FieldsetReset>
      ) : (
        <div style={{ marginBlockStart: '0.75rem' }}>{children}</div>
      )}
    </div>
  );
}
