import { submitSearch } from '@iabbb/shared/containers/Header/actions';
import HoneypotField from '@iabbb/shared/components/General/HoneypotField';
import { useCallback, useRef } from 'react';
import { useDispatch } from 'react-redux';
import urlJoin from 'url-join';

interface SearchFormProps {
  children?: React.ReactNode;
  className?: string;
}

function SearchForm({ children, ...props }: SearchFormProps) {
  const dispatch = useDispatch();
  const formRef = useRef<HTMLFormElement | null>(null);

  const onSubmit = useCallback(
    (e) => {
      if ('FormData' in window) {
        e.preventDefault();

        if (!formRef.current) {
          return;
        }

        const formData = new FormData(formRef.current);
        dispatch(submitSearch({ isBot: formData.has('honeypot') && formData.get('honeypot') === '1' }) as never);
      }
    },
    [dispatch],
  );

  return (
    <form
      action={urlJoin(process.env.NEXT_PUBLIC_BASE_URL, 'search')}
      onSubmit={onSubmit}
      name="search-form"
      ref={formRef}
      {...props}
    >
      <HoneypotField />
      {children}
    </form>
  );
}

export default SearchForm;
