import styled from '@emotion/styled';
import { css } from '@emotion/react';

const listResetStyles = css`
  list-style: none;
  padding-left: 0;
`;

const OlListReset = styled.ol`
  ${listResetStyles}
`;

const UlListReset = styled.ul`
  ${listResetStyles}
`;

export { listResetStyles, OlListReset, UlListReset };
