import { Global, css } from '@emotion/react';
import {
  FontStyles,
  HideAtStyles,
  PrintStyles,
  RootStyles,
  LinkStyles,
} from '@iabbb/shared/styles/BaselineStyles';

export default function BaselineStyles() {
  return (
    <Global
      styles={css`
        ${FontStyles}

        body {
          font-family: var(--bds-font-family-base);
          margin: 0;
        }

        ${PrintStyles}
        ${HideAtStyles}
        ${LinkStyles}
        .bbb-layout-wrapper {
          ${RootStyles}

          h1,
        h2,
        h3,
        h4,
        h5,
        h6,
        p,
        ul,
        ol,
        dl {
            margin: 0;
          }

          sup {
            position: relative;
            top: -0.5em;
            vertical-align: baseline;
          }

          // 👇 in Chrome/Safari, a native cancel button is shown for Find typeahead, but we have our own
          input[type='search']::-webkit-search-cancel-button,
          input[type='search']::-webkit-search-decoration {
            -webkit-appearance: none;
          }

          /* 👇 Remove all animations, transitions and smooth scroll for people that prefer not to see them */
          @media (prefers-reduced-motion: reduce) {
            *,
            *::before,
            *::after {
              animation-duration: 0.01ms !important;
              animation-iteration-count: 1 !important;
              transition-duration: 0.01ms !important;
              scroll-behavior: auto !important;
            }
          }
        }

        .with-icon {
          /* ↓ Set the inline-flex context, which eliminates the word space */
          display: inline-flex;
          align-items: center;
          line-height: var(--bds-font-leading-1);
        }

        .with-icon[data-icon-align='first-line'] {
          align-items: flex-start;
          line-height: inherit;
        }

        .with-icon .icon {
          flex-shrink: 0;
          /* ↓ Use the logical margin property and a --icon-space variable with a fallback */
          margin-inline-end: var(--icon-space, 0.375em);
        }
      `}
    />
  );
}
