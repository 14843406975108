import styled from '@emotion/styled';

const Item = styled.div`
  align-items: center;
  display: flex;
  flex-grow: 1;
`;

Item.FlagImg = styled.img`
  height: 14px;
  width: 21px;
`;

Item.Label = styled.span`
  margin-left: 8px;
`;

export default Item;
