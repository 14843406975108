import styled from '@emotion/styled';

export const StyledDetails = styled.details`
  margin-block-start: 0.5rem;

  summary {
    color: var(--bds-color-blue-dark);
    cursor: pointer;
    display: inline-block;
    padding-inline-start: 1.25rem;
    position: relative;
    text-decoration: underline;
  }

  summary::before {
    content: '';
    position: absolute;
    inset-block-start: 1px;
    inset-block-end: 0;
    inset-inline-start: 0;
    margin: auto;
    display: block;
    inline-size: 0;
    block-size: 0;
    border-style: solid;
    border-color: rgba(0, 0, 0, 0);
    border-inline-start-color: rgba(0, 0, 0, 0);
    clip-path: polygon(0% 0%, 100% 50%, 0% 100%);
    border-width: 7px 0 7px 12.124px;
    border-inline-start-color: inherit;
  }

  &[open] summary::before {
    display: block;
    inline-size: 0;
    block-size: 0;
    border-style: solid;
    border-color: rgba(0, 0, 0, 0);
    border-top-color: rgba(0, 0, 0, 0);
    -webkit-clip-path: polygon(0% 0%, 50% 100%, 100% 0%);
    clip-path: polygon(0% 0%, 50% 100%, 100% 0%);
    border-width: 12.124px 7px 0 7px;
    border-top-color: inherit;
  }

  summary span {
    text-decoration: underline;
  }

  summary + div {
    border-inline-start: 5px solid var(--bds-color-gray-50);
    margin-inline-start: 0.25rem;
    padding-block: 0.75rem;
    padding-inline-start: 1.25rem;
  }
`;
