import styled from '@emotion/styled';

export default styled.section`
  background: var(--bds-color-gray-50);
  inline-size: 100%;
  line-height: var(--bds-font-leading-3);
  margin: 0;
  max-inline-size: none;
  padding-block: 1.25rem; // 👈 inline padding adding with Center
  z-index: 4; // 👈 overlay header and Scamtracker banner

  // 👇 At very small viewport sizes/zoom levels, this gets quite unwieldy.
  // Only use fixed position at reasonable viewport sizes.
  @media (min-width: 20em) {
    inset-block-end: 0;
    position: fixed;
  }

  // this is included in the base stylesheet, but not the header/footer one
  p {
    margin: 0;
  }

  .layout {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    gap: 1.5rem;
    justify-content: space-between;

    > p {
      flex-basis: 0;
      flex-grow: 999;
      min-inline-size: 65%;
    }
  }
`;
